import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

import "scripts/frontend"
import "styles/frontend"
import "controllers"
const images = require.context('../images', true)
