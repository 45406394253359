import { Controller } from 'stimulus'
import swal from 'sweetalert';

export default class extends Controller {
  static targets = ["checkTerm", "amount", "totalAmount", "quantity"]

  setQuantity() {
    if (this.quantityTarget.value.length < 1) {
      setTimeout(() => this.quantityTarget.value = 1, 200)
    }
    this.setPrice()
  }

  increase(e) {
    e.preventDefault()
    this.quantityTarget.value = parseInt(this.quantityTarget.value) + 1;
    this.setPrice()
  }

  decrease(e) {
    e.preventDefault()
    if (this.quantityTarget.value > 1) {
      this.quantityTarget.value = parseInt(this.quantityTarget.value) - 1;
    }
    this.setPrice()
  }

  setPrice() {
    let quantity = this.quantityTarget.value
    let amount = this.amountTarget.textContent
    amount = parseInt(amount.replace(",", ""))
    let totalAmount = (quantity * amount).toLocaleString('en-US')
    this.totalAmountTarget.textContent = totalAmount < 1 ? amount : totalAmount
  }

  submit(e) {
    if (!this.checkTermTarget.checked) {
      e.preventDefault()
      swal({
        icon: "warning",
        title: "請同意服務條款與隱私權政策"
      })
    }
  }
}
