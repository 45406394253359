import Flatpickr from 'stimulus-flatpickr'
import 'flatpickr/dist/themes/material_red.css'

export default class extends Flatpickr {
  static targets = []

  initialize() {
    this.config = {
      enableTime: true,
      time_24hr: true
    }
  }

  connect() {
    super.connect()
  }
}
