import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faTrashAlt,
  faHomeLg,
  faChevronRight,
  faTimesCircle,
  faFlagCheckered,
  faTicketAlt,
  faLink,
  faPauseCircle,
  faExclamationTriangle,
  faPlus,
  faMinus,
  faAngleDown
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faTrashAlt,
  faHomeLg,
  faChevronRight,
  faTimesCircle,
  faFlagCheckered,
  faTicketAlt,
  faLink,
  faPauseCircle,
  faExclamationTriangle,
  faPlus,
  faMinus,
  faAngleDown
);

dom.watch();
