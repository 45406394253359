import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  confirm(e) {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "確定要取消訂單或退款？",
      buttons: {
        cancel: "取消",
        confirm: "確認"
      }
    })
    .then(res => {
      res && e.target.parentNode.submit();
    })
  }
}
