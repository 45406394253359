import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectBox", "mobileBarCodeBox", "mobileBarCodeField"];

  connect() {
    this.toggleBarCodeField();
  }

  toggleBarCodeField() {
    if (this.selectBoxTarget.value == "mobile_carrier") {
      this.mobileBarCodeBoxTarget.classList.remove("hidden");
    } else {
      this.mobileBarCodeBoxTarget.classList.add("hidden");
    }
  }

  upperCaseValue() {
    this.mobileBarCodeFieldTarget.value =
      this.mobileBarCodeFieldTarget.value.toUpperCase();
  }
}
